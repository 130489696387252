import {
    Box,
    BoxProps,
    Button,
    Stack,
    Typography,
    Link as MuiLink,
    capitalize,
    LinkProps,
    TextFieldProps,
    Select,
    MenuItem,
    SelectProps,
    Tooltip,
} from "@mui/material";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { TBooking, useBookings } from "../../Hooks/useBookings";
import React, { ReactNode, useEffect, useLayoutEffect, useState } from "react";
import { StatusChip } from "../../Components/StatusChip";
import { OptionsDialog } from "../OptionsDialog";
import { ConfirmDialog } from "../ConfirmDialog";
import { useTranslate, TranslateFunction } from "../../Hooks/useTranslate";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ProgressButton } from "../../Components/ProgressButton/ProgressButton";
import { updatePayment } from "../../Api";
import randomBytes from "randombytes";
import { prepareGuests } from "../CreateBookingDialog";
import { useUpcoming } from "../../Hooks/useUpcoming";
import { SimpleSkeleton } from "../../Components/SimpleSkeleton/SimpleSkeleton";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider, useController } from "react-hook-form";
import { renderDateTime } from "@hiddengemgroup/utils-date";
import { formatMoney } from "../../Components/VariantSelect/VariantSelect";
import { ViewHeader } from "./ViewHeader";
import { ViewFooter } from "./ViewFooter";
import { LoadingRow } from "../../Components/LoadingRow";
import { renderChannel } from "../../Components/BookingsList/BookingsList";
import { CONSUMER_URL } from "../../Utils/constants";
import { TDiscount } from "../../Hooks/useDiscount";
import SolidTextField from "../../Components/SolidTextField/SolidTextField";
import { useDebounce } from "../../Hooks/useDebounce";
import { LabelWithButton } from "../../Components/LabelWithButton";
import { defaultTaxOption } from "../../Utils/taxOptions";
import { useService } from "../../Hooks/useService";
import { Payment } from "../../Api/Payment";
import { useLocalizer } from "../../Pages/Events/EventSyiSections/EventSyiSectionDetails";
import { useProfile } from "../../Hooks/useProfile";
import { calculateExpiresIn, renderGuestTypes } from "../../Utils/helpers";
import { useEvent } from "../../Hooks/useEvent";

type TRow<T extends TBooking = TBooking> = {
    key: string;
    label: string;
    value: (
        el: T,
        handleClose?: () => void,
        t?: TranslateFunction,
        language?: string,
        returnUrl?: string
    ) => string | number | ReactNode;
    props?: BoxProps;
};

const _rows: TRow[] = [
    {
        key: "bookingId",
        label: "Booking ID",
        value: (el) => el.id,
    },
    {
        key: "paymentDate",
        label: "Betalingstidpunkt",
        value: (el) =>
            el.transaction?.timestamp ? (
                <LabelWithButton
                    label={renderDateTime(el.transaction.timestamp)}
                    buttonLabel={"Vis kvittering"}
                    href={el.receiptId ? `${CONSUMER_URL}/receipt/${el.receiptId}` : undefined}
                />
            ) : (
                <em>Ikke betalt endnu</em>
            ),
    },
    {
        key: "price",
        label: "Beløb",
        value: (el, _, t) =>
            typeof el.transaction?.totalAmount === "number" &&
            formatMoney(t as TranslateFunction)({
                value: el.transaction?.totalAmount ?? 0,
                nativeCurrency: el.transaction?.currency ?? "dkk",
            }),
    },
    {
        key: "status",
        label: "Status",
        value: (el, _, t) => <BookingStatusChip bookingId={el.id} />,
    },
    /*{
        key: "source",
        label: "Source",
        value: (el) => capitalize(el?.source ?? "checkout"),
    },*/
    {
        key: "channel",
        label: "Kanal",
        value: (el) => capitalize(el?.channel ?? "Spiritworld"),
    },
    {
        key: "service",
        label: "Ydelse",
        value: (el, handleClose, _t, _lang, returnUrl) => (
            <Link
                to={`/service/${el.serviceId}`}
                state={returnUrl ? { returnUrl } : undefined}
                onClick={handleClose}
            >
                <MuiLink>{el.serviceHeadline ?? ""}</MuiLink>
            </Link>
        ),
    },
    {
        key: "time",
        label: "Tidspunkt",
        value: (el) =>
            renderDateTime(el.startDateTime, el.endDateTime, {
                standard: "ddmyyyyhh",
                sameDay: "dmhh",
            }),
    },
    {
        key: "countParticipants",
        label: "Antal personer",
        value: (el) => countVariants(el.items),
    },
    {
        key: "variants",
        label: "Variant",
        value: (el, handleClose, _, language) => renderGuestTypes(el, undefined, language),
    },
    {
        key: "type",
        label: "Type",
        value: (el, handleClose, _, language) => renderChannel(el, false, { fontSize: "1em" }),
    } /*,
{
    key: 'channel',
    label: 'Kilde',
    value: (el) => `${el.channel ?? 'Holdbar'} - ${el.source ?? 'Website'}`
}*/,
];

const BookingStatusChip = ({ bookingId }: { bookingId: string }) => {
    const { t } = useTranslate("utils");
    const {
        booking: { data: booking },
        updateBooking,
    } = useBookings(undefined, bookingId);
    const handleUpdate: SelectProps["onChange"] = (evt) => {
        updateBooking.mutate({
            id: bookingId,
            status: evt.target.value as "paid" | "unpaid",
            transaction: {
                ...booking?.transaction,
                timestamp: new Date().toISOString(),
            },
        });
    };

    return typeof booking?.transaction?.totalAmount !== "number" &&
        !booking?.offerId &&
        booking?.source === "manual" &&
        (booking?.status === "paid" ||
            booking?.status === "unpaid" ||
            booking?.status === "active") ? (
        <Select
            sx={{ "& .MuiOutlinedInput-input": { py: 1, pl: 1 } }}
            defaultValue={booking.status}
            onChange={handleUpdate}
        >
            <MenuItem value={"paid"}>
                <StatusChip size={"small"} status={"paid"} />
            </MenuItem>
            <MenuItem value={"unpaid"}>
                <StatusChip size={"small"} status={"unpaid"} />
            </MenuItem>
        </Select>
    ) : (
        <Box>
            <StatusChip
                size={"small"}
                status={
                    ((booking?.status === "active" && booking?.transaction?.timestamp) ||
                        booking?.offerId) &&
                    booking?.status !== "cancelled" &&
                    booking?.status !== "moved"
                        ? "paid"
                        : booking?.status ?? "loading"
                }
            />
            {booking?.refund?.timestamp && (
                <Typography variant={"body2"} ml={1}>
                    {t?.("refunded", "utils.generic")} ({renderDateTime(booking?.refund?.timestamp)}
                    )
                </Typography>
            )}
        </Box>
    );
};

const useMeetingLink = (bookingId: string) => {
    const { booking } = useBookings(undefined, bookingId);
    const { event } = useEvent(booking.data?.eventId);
    const { service } = useService(booking.data?.serviceId);
    return asHref(event.data?.meetingLink ?? service.data?.meetingLink);
};

const countVariants = (obj?: object) =>
    Object.entries(obj ?? {}).reduce((total, [id, count]) => {
        return id.startsWith("variant") ? total + Number(count) : total;
    }, 0);

export const BookingView = ({
    bookingId,
    onBack,
    onLoadCustomer,
    onClickMoveBooking,
    ...props
}: BoxProps & {
    onClickMoveBooking?: () => void;
    onLoadCustomer: (customerId: string) => void;
    bookingId: string;
    onBack: () => void;
}) => {
    const { t } = useTranslate("dialogs.booking");
    const { i18n } = useTranslation();

    const navigate = useNavigate();

    const location = useLocation();

    const localize = useLocalizer();

    const { defaultCurrency } = useProfile();

    const meetingLink = useMeetingLink(bookingId);

    const debounce = useDebounce(1000);

    const [isMoving, setIsMoving] = useState(false);

    const modal = useModal();

    const {
        booking: { isLoading },
        enrichedBooking: booking,
        cancelBooking,
        refundBooking,
        createBooking,
        updateBooking,
    } = useBookings(undefined, bookingId);

    const { getVariant } = useService();

    const { getValues, setValue, control, ...methods } = useForm();

    const { field: internalNote } = useController({ name: "internalNote", control });

    const handleClickDiscount: LinkProps["onClick"] = (evt) => {
        evt.preventDefault();
        if (booking?.discount?.id) {
            handleClose();
            navigate(`/discount/${booking.discount.id}`, {
                state: { returnUrl: location.pathname },
            });
        }
    };

    const rows: TRow<TBooking & { discount?: TDiscount }>[] = [
        ..._rows.filter((el) => {
            if (booking?.sessionId) {
                if (booking?.source === "manual") {
                    return !["countParticipants", "variants", "price", "paymentDate"].includes(
                        el.key
                    );
                }
                return !["countParticipants"].includes(el.key);
            }
            return true;
        }),
        ...(booking?.discount
            ? [
                  {
                      key: "discount",
                      label: "Rabatkode",
                      value: (val: { discount?: TDiscount }) => (
                          <Typography>
                              <MuiLink sx={{ mr: 1 }} onClick={handleClickDiscount}>
                                  {val?.discount?.code}
                              </MuiLink>
                              ({val?.discount?.rate}
                              {val?.discount?.percentage ? "%" : "kr."})
                          </Typography>
                      ),
                  },
              ]
            : []),
    ];

    const handleClose = () => {
        modal.remove();
    };

    useLayoutEffect(() => {
        if (booking?.customer?.id) {
            onLoadCustomer(booking.customer.id);
        }
    }, [booking]);

    useEffect(() => {
        if (booking?.internalNote) {
            setValue("internalNote", booking.internalNote, { shouldTouch: true });
        }
    }, [booking]);

    const handleRefund = async () => {
        try {
            if (booking?.transaction === undefined) {
                return;
            }
            await NiceModal.show(ConfirmDialog, {
                title: t("title", "dialogs.refund"),
                headline: t("headline", "dialogs.refund", {
                    refundAmount: `${
                        booking?.transaction?.totalAmount
                    } ${booking?.transaction?.currency?.toUpperCase()}`,
                    customerName: booking?.customer.name,
                }),
                confirmLabel: t("actions.primary", "dialogs.refund"),
            });
            return refundBooking.mutate();
        } catch (err) {
            console.log(err);
        }
    };

    const handleCancel = async () => {
        let choice: string | undefined;
        try {
            if (booking?.refund?.timestamp || !booking?.transaction) {
                await NiceModal.show(ConfirmDialog, {
                    title: t("title", "dialogs.cancelBooking"),
                    headline: t("headline", "dialogs.cancelBooking", {
                        customerName: booking?.customer.name,
                    }),
                    confirmLabel: t("actions.primary", "dialogs.cancelBooking"),
                });
            } else {
                choice = await NiceModal.show(OptionsDialog, {
                    title: t("title", "dialogs.refund"),
                    headline: t("headline", "dialogs.cancelBooking", {
                        customerName: booking?.customer.name,
                    }),
                    buttons: [
                        {
                            key: "cancelOnly",
                            label: t("cancelOnly", "dialogs.cancelBooking.actions"),
                            props: {
                                variant: "outlined",
                                color: "secondary",
                            },
                        },
                        {
                            key: "shouldRefund",
                            label: t("shouldRefund", "dialogs.cancelBooking.actions"),
                            props: {
                                variant: "contained",
                            },
                        },
                    ],
                });
            }
            cancelBooking.mutate(choice === "shouldRefund");
        } catch (err) {}
    };

    const { products } = useUpcoming(booking?.serviceId);
    const handleCreatePaymentLink = async () => {
        try {
            const paymentId = randomBytes(16).toString("hex");

            const payload: Partial<Payment> = {
                subject: "booking",
                subjectId: bookingId,
                expiresIn: calculateExpiresIn(new Date(), booking?.startDateTime, 60 * 24 * 14),
            };

            if (booking?.sessionId) {
                const [variantId] = Object.keys(booking.items);
                const variant = getVariant(variantId, booking.serviceId);
                if (!variant) {
                    throw new Error("Missing variant");
                }
                payload.items = {
                    [`variant/${booking.variantId}`]: {
                        quantity: 1,
                        name: localize(variant.name),
                        price: {
                            amount: variant.price,
                            taxId: variant.vatId ?? defaultTaxOption,
                            currency: defaultCurrency,
                        },
                        sessionId: booking.sessionId,
                        serviceId: booking.serviceId,
                    },
                };
            }
            if (booking?.eventId && products) {
                payload.items = {
                    ...prepareGuests(booking?.items ?? {}, products),
                };
            }

            if (!payload.items) {
                throw new Error("Missing payment items");
            }

            await updatePayment(paymentId, payload as Payment);
            return updateBooking.mutateAsync({
                id: bookingId,
                paymentId,
                ...(booking?.eventId && {
                    eventId: booking.eventId,
                }),
                ...(booking?.sessionId && {
                    sessionId: booking.sessionId,
                }),
            });
        } catch (err) {
            console.log(err);
        }
    };
    const handleChangeNote: TextFieldProps["onChange"] = (evt) => {
        internalNote.onChange(evt);
        debounce(() =>
            updateBooking.mutate({
                id: bookingId,
                internalNote: evt.target.value,
            })
        );
    };

    const handleDelete = () => {};

    return (
        <Box {...props}>
            <FormProvider {...{ getValues, setValue, control, ...methods }}>
                <ViewHeader title={booking?.serviceHeadline} onBack={onBack} />
                <Stack spacing={0.5}>
                    {booking && !createBooking.isLoading ? (
                        rows.map((row) => (
                            <LoadingRow
                                key={row.key}
                                label={t(row.key, "utils.tables.header")}
                                value={row.value(
                                    booking as TBooking,
                                    handleClose,
                                    t,
                                    i18n.language ?? "da",
                                    location.pathname
                                )}
                                isLoading={isLoading}
                                {...row.props}
                            />
                        ))
                    ) : (
                        <SimpleSkeleton />
                    )}

                    {booking?.contactChannel === "online" && meetingLink && (
                        <Box display={"flex"} mt={2}>
                            <Typography
                                fontSize={"inherit"}
                                fontWeight={600}
                                width={"100%"}
                                maxWidth={160}
                            >
                                {t("meetingLink")}
                            </Typography>
                            <a href={meetingLink} target={"_blank"}>
                                <Tooltip title={meetingLink}>
                                    <MuiLink>{t("goToMeeting")}</MuiLink>
                                </Tooltip>
                            </a>
                        </Box>
                    )}

                    {booking?.contactChannel !== "online" && (
                        <Box display={"flex"} mt={2}>
                            <Typography
                                fontSize={"inherit"}
                                fontWeight={600}
                                width={"100%"}
                                maxWidth={160}
                            >
                                Adresse
                            </Typography>
                            <Typography whiteSpace={"pre-wrap"} fontSize={"inherit"}>
                                {booking?.location}
                            </Typography>
                        </Box>
                    )}

                    {booking?.customDataInputs && (
                        <Box display={"flex"} mt={2}>
                            <Typography
                                fontSize={"inherit"}
                                fontWeight={600}
                                width={"100%"}
                                maxWidth={160}
                            >
                                {t("specialInfo")}
                            </Typography>
                            <Box>
                                {booking?.customDataInputs?.map((el, i) => (
                                    <Typography fontSize={"inherit"} fontWeight={500}>
                                        <strong>{el.guestName}</strong>:{" "}
                                        {el.inputs
                                            .map((inp) => `${inp.name} ${inp.value}`)
                                            .join(", ")}
                                    </Typography>
                                ))}
                            </Box>
                        </Box>
                    )}

                    {booking?.paymentId &&
                        !booking.transaction?.totalAmount &&
                        booking.source === "manual" && (
                            <Box display={"flex"} mt={2}>
                                <Typography
                                    fontSize={"inherit"}
                                    fontWeight={600}
                                    width={"100%"}
                                    maxWidth={160}
                                >
                                    {t("paymentLink")}
                                </Typography>
                                <MuiLink
                                    href={`${CONSUMER_URL}/payment/${booking.paymentId}`}
                                    target={"_blank"}
                                >
                                    Gå til betaling
                                </MuiLink>
                            </Box>
                        )}

                    {booking?.movedToEvent && !isMoving && (
                        <Box display={"flex"} mt={2}>
                            <Typography
                                fontSize={"inherit"}
                                fontWeight={600}
                                width={"100%"}
                                maxWidth={160}
                            >
                                {t("movedTo")}
                            </Typography>
                            <Link to={`/event/${booking.movedToEvent}`} onClick={handleClose}>
                                <MuiLink>{t("goToEvent")}</MuiLink>
                            </Link>
                        </Box>
                    )}

                    {booking?.movedFromEvent && (
                        <Box display={"flex"} mt={2}>
                            <Typography
                                fontSize={"inherit"}
                                fontWeight={600}
                                width={"100%"}
                                maxWidth={160}
                            >
                                {t("movedFrom")}
                            </Typography>
                            <Link to={`/event/${booking.movedFromEvent}`} onClick={handleClose}>
                                <MuiLink>{t("goToEvent")}</MuiLink>
                            </Link>
                        </Box>
                    )}

                    {booking?.source === "manual" &&
                        !booking?.paymentId &&
                        (booking.eventId || booking.sessionId) &&
                        booking?.offerId === undefined && (
                            <Box display={"flex"} mt={2} alignItems={"center"}>
                                <Typography
                                    fontSize={"inherit"}
                                    fontWeight={600}
                                    width={"100%"}
                                    maxWidth={160}
                                >
                                    {t("paymentLink")}
                                </Typography>
                                <ProgressButton
                                    sx={{ ml: -1 }}
                                    variant={"text"}
                                    disabled={booking.eventId ? !products : false}
                                    label={t(
                                        "generatePaymentLink",
                                        "dialogs.createBooking.actions"
                                    )}
                                    onClick={handleCreatePaymentLink}
                                />
                            </Box>
                        )}
                </Stack>
                <SolidTextField
                    sx={{ mt: 2 }}
                    InputLabelProps={{ shrink: internalNote.value }}
                    label={t("internalNote", "utils.generic")}
                    fullWidth
                    disabled={isLoading}
                    multiline
                    minRows={3}
                    defaultValue={internalNote.value}
                    onChange={handleChangeNote}
                />

                <ViewFooter mt={3}>
                    {booking?.status !== "cancelled" && (
                        <Button variant={"outlined"} color={"secondary"} onClick={handleCancel}>
                            {t("actions.tertiary")}
                        </Button>
                    )}
                    {!booking?.refund?.timestamp &&
                        typeof booking?.transaction?.totalAmount === "number" && (
                            <Button variant={"outlined"} color={"secondary"} onClick={handleRefund}>
                                {t("actions.secondary")}
                            </Button>
                        )}
                    {booking?.status !== "cancelled" && (
                        <Button
                            variant={"outlined"}
                            color={"secondary"}
                            onClick={onClickMoveBooking}
                        >
                            {t("actions.moveBooking")}
                        </Button>
                    )}

                    {booking?.customer?.email && (
                        <a href={`mailto:${booking?.customer?.email}`}>
                            <Button variant={"outlined"} color={"secondary"}>
                                {t("actions.primary")}
                            </Button>
                        </a>
                    )}
                    {booking?.status === "unpaid" && (
                        <Button variant={"outlined"} color={"error"} onClick={handleDelete}>
                            {t("actions.deleteBooking")}
                        </Button>
                    )}
                </ViewFooter>
            </FormProvider>
        </Box>
    );
};

const asHref = (link: string | undefined) => {
    if (link === undefined) {
        return undefined;
    }
    if (link.startsWith("http")) {
        if (link.startsWith("https")) {
            return link;
        }
        const [_, path] = link.split("http://");
        return `https://${path}`;
    }
    return `https://${link}`;
};
