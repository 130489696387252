import { useMutation, useQuery, useQueryClient } from "react-query";
import * as api from "../Api";
import { IService, useService } from "./useService";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";
import { useLocalizer } from "../Pages/Events/EventSyiSections/EventSyiSectionDetails";
import { sortByLatestCreated, wait } from "../Utils/helpers";
import { useDiscount } from "./useDiscount";
import { Reminder, useReminders } from "./useNotifications";
import { useProfile } from "./useProfile";
import { addMinutes, areIntervalsOverlapping } from "date-fns";

type Localized = { [k: string]: string };

type TCustomer = {
    id: string;
    name: string;
    phone: string | null;
    email: string;
    address?: {
        city?: string;
        postal_code?: string;
        line1: string;
    };
};

export interface TBooking {
    title?: string;
    paymentId?: string;
    hasReminders?: boolean;
    serviceHeadline?: string;
    variants?: { name: string | Localized; id: string }[];
    id: string;
    created: string;
    status: "active" | "cancelled" | "moved" | "paid" | "unpaid";
    eventId: string;
    sessionId?: string;
    movedToEvent?: string;
    movedFromEvent?: string;
    serviceId: string;
    items: {
        [id: string]: number;
    };
    assignees?: string[];
    transaction?: {
        totalAmount?: number;
        currency?: string;
        timestamp: string;
    };
    refund?: {
        timestamp: string;
    };
    metadata?: {
        shouldNotify: boolean;
        messageInNotification?: string;
    };
    variantId: string;
    contactChannel?: string;
    parentId?: string;
    offerId?: string;
    receiptId?: string;
    internalNote?: string;
    startDateTime: string;
    endDateTime: string;
    location?: string | undefined;
    customer: TCustomer;
    customDataInputs?: {
        guestName: string;
        inputs: { name: string; id: string; value: string }[];
    }[];
    channel?: string;
    funnelId?: string;
    campaignId?: string;
    source?: string | "manual" | "checkout";
    type?: "blocker";
}

export type TCreateBookingPayload = {
    status: "active" | "cancelled" | "unpaid";
    eventId: string;
    id?: string;
    serviceId: string;
    location: string | undefined;
    shouldNotify?: boolean;
    items: {
        [id: string]: string;
    };
    internalNote?: string;
    startDateTime: string;
    endDateTime: string;
    customer: {
        name: string;
        email: string;
        phone?: string;
    };
    channel: "spiritworld" | "integration";
    source: "manual" | "checkout";
    metaData?: {
        skipBookingConfirmation: boolean;
    };
};

export function getLocalized(
    name: string | Localized | undefined,
    language: string
): string | undefined {
    if (typeof name === "string") {
        return name;
    }
    if (name === undefined) {
        return undefined;
    }
    try {
        if (name[language]) {
            return name[language];
        }
        const [value] = Object.values(name);
        return value;
    } catch {
        return undefined;
    }
}

const getVariants = (service: IService | undefined, language: string) => {
    return (
        service?.variants?.reduce<{ name: any; id: string }[]>((acc, { addons, name, id }) => {
            return [
                ...acc,
                ...(addons ?? []).map((el) => ({
                    name: getLocalized(el.name, language),
                    id: el.id,
                })),
                { name: getLocalized(name, language), id },
            ];
        }, []) ?? []
    );
};

export const useBookings = (
    eventId?: string,
    bookingId?: string,
    sessionId?: string,
    customerId?: string
) => {
    const queryClient = useQueryClient();

    const { services } = useService();

    const { redemptions } = useDiscount();

    const { userinfo } = useProfile();

    const localize = useLocalizer();

    const { i18n } = useTranslation("translation");

    const { allReminders } = useReminders("booking");

    const BookingsQueryKey = ["bookings"];
    const BookingsForEventQueryKey = ["bookings", "event", eventId];
    const BookingsForCustomerQueryKey = ["bookings", "customer", customerId];
    const BookingsForSessionQueryKey = ["bookings", "session", sessionId];
    const SingleBookingQueryKey = ["booking", bookingId];

    const bookings = useQuery<TBooking[]>(
        BookingsQueryKey,
        async () => {
            await queryClient.cancelQueries(BookingsQueryKey);
            const data = await api.getBookings();
            const reminders = queryClient.getQueryData<Reminder[]>(["reminders", "booking"]);
            return sortByLatestCreated(
                data.map((booking) => {
                    queryClient.setQueryData(["booking", booking.id], booking);
                    const foundService =
                        services.data?.find((ser) => ser.id === booking.serviceId) ??
                        ({} as IService);
                    return {
                        ...booking,
                        variants: getVariants(foundService, i18n.language),
                        hasReminders: reminders?.some((el) => el.subjectId === booking.id) ?? false,
                        serviceHeadline:
                            (Object.values(foundService?.headline ?? {})?.[0] as string) ?? "",
                    };
                })
            );
        },
        {
            onSettled: () => {
                return queryClient.invalidateQueries(["calendar", "events"]);
            },
            enabled: Boolean(services.data) && Boolean(allReminders.data),
        }
    );

    const booking = useQuery<TBooking>(
        SingleBookingQueryKey,
        async () => {
            await queryClient.cancelQueries(SingleBookingQueryKey);
            return api.getBooking(bookingId as string);
        },
        {
            enabled: Boolean(bookingId),
        }
    );

    const enrichedBooking = useMemo(() => {
        if (booking.data) {
            const foundService =
                services.data?.find((ser) => ser.id === booking.data.serviceId) ?? ({} as IService);
            const discount = redemptions.data?.find(
                (el) => el.receiptId === booking.data?.receiptId
            );
            return {
                ...booking.data,
                ...(discount && { discount }),
                variants: getVariants(foundService, i18n.language),
                serviceHeadline: (Object.values(foundService?.headline ?? {})?.[0] as string) ?? "",
            };
        }
    }, [booking, bookingId, redemptions.data]);

    const bookingsForEvent = useQuery<TBooking[]>(
        BookingsForEventQueryKey,
        async () => {
            await queryClient.cancelQueries(BookingsForEventQueryKey);
            const bookings = queryClient.getQueryData<TBooking[]>(BookingsQueryKey);
            const sorted = sortByLatestCreated(
                bookings?.filter((el) => el.eventId === eventId) ?? []
            );
            return sorted;
        },
        {
            enabled: Boolean(bookings.data) && Boolean(eventId),
        }
    );

    const bookingsForCustomer = useQuery<TBooking[]>(
        BookingsForCustomerQueryKey,
        async () => {
            await queryClient.cancelQueries(BookingsForCustomerQueryKey);
            const bookings = queryClient.getQueryData<TBooking[]>(BookingsQueryKey);
            return sortByLatestCreated(
                bookings
                    ?.filter((el) => el.customer?.id === customerId)
                    ?.map((m) => {
                        const { headline } =
                            services.data?.find((el) => el.id === m.serviceId) ?? {};
                        return {
                            ...m,
                            serviceName: localize(headline),
                        };
                    }) ?? []
            );
        },
        {
            enabled: Boolean(bookings.data) && Boolean(customerId) && Boolean(services.data),
        }
    );

    const _bookingsForSession = useQuery<TBooking[]>(
        BookingsForSessionQueryKey,
        async () => {
            await queryClient.cancelQueries(BookingsForSessionQueryKey);
            const _bookings = queryClient.getQueryData<TBooking[]>(BookingsQueryKey);
            return (
                (_bookings ?? (await bookings.refetch())?.data)?.filter(
                    (el) => el.sessionId === sessionId
                ) ?? []
            );
        },
        {
            enabled: Boolean(bookings.data) && Boolean(sessionId),
        }
    );

    const bookingsForSession = useMemo(() => {
        return sortByLatestCreated(_bookingsForSession.data ?? []);
    }, [_bookingsForSession]);

    const introSessionsForSession = useMemo(() => {
        return sortByLatestCreated(
            _bookingsForSession.data?.filter((el) => {
                return el.variantId?.startsWith("intro");
            }) ?? []
        );
    }, [_bookingsForSession]);

    const cancelBooking = useMutation(
        (shouldRefund: boolean = false) => api.cancelBooking(bookingId!, shouldRefund),
        {
            onMutate: async (shouldRefund) => {
                await queryClient.cancelQueries(BookingsQueryKey);

                const previous = queryClient.getQueryData<TBooking>(SingleBookingQueryKey);

                queryClient.setQueryData<TBooking[]>(BookingsQueryKey, (prev) => {
                    return prev!.map((el) => {
                        return el.id === bookingId
                            ? {
                                  ...el,
                                  hasReminders: false,
                                  status: "cancelled",
                                  ...(shouldRefund && {
                                      refund: { timestamp: new Date().toISOString() },
                                  }),
                              }
                            : el;
                    });
                });

                queryClient.setQueryData<TBooking[]>(
                    ["bookings", "event", previous?.eventId],
                    (prev) => {
                        return (
                            prev?.map((el) => {
                                return el.id === bookingId
                                    ? {
                                          ...el,
                                          hasReminders: false,
                                          status: "cancelled",
                                          ...(shouldRefund && {
                                              refund: { timestamp: new Date().toISOString() },
                                          }),
                                      }
                                    : el;
                            }) ?? []
                        );
                    }
                );

                queryClient.setQueryData<TBooking>(SingleBookingQueryKey, (prev) => {
                    return {
                        ...prev!,
                        status: "cancelled",
                        hasReminders: false,
                        ...(shouldRefund && {
                            refund: { timestamp: new Date().toISOString() },
                        }),
                    };
                });

                return { previous, eventId: previous?.eventId, serviceId: previous?.serviceId };
            },
            onError: (err, variables, context: any) => {
                if (context?.previous) {
                    queryClient.setQueryData<TBooking>(SingleBookingQueryKey, context.previous);
                }
            },
            onSettled: async (data, err, variables, context: any) => {
                await wait(5000);
                await queryClient.invalidateQueries(["reminders", "booking"]);
                await queryClient.invalidateQueries(BookingsQueryKey);
                queryClient.invalidateQueries("events");
                queryClient.invalidateQueries([
                    "events",
                    { type: "service", serviceId: context?.serviceId },
                ]);
                queryClient.invalidateQueries(["bookings", "event", context?.eventId]);
                queryClient.invalidateQueries(["vouchers", "bought"]);
            },
        }
    );

    const refundBooking = useMutation(() => api.refundBooking(bookingId!), {
        onMutate: async () => {
            const payload = { refund: { timestamp: new Date().toISOString() } };

            await queryClient.cancelQueries(BookingsQueryKey);

            const previous = queryClient.getQueryData<TBooking>(SingleBookingQueryKey);

            queryClient.setQueryData<TBooking[]>(BookingsQueryKey, (prev) => {
                return prev!.map((el) => {
                    return el.id === bookingId ? { ...el, ...payload } : el;
                });
            });

            queryClient.setQueryData<TBooking[]>(
                ["bookings", "event", previous?.eventId],
                (prev) => {
                    return (
                        prev?.map((el) => {
                            return el.id === bookingId ? { ...el, ...payload } : el;
                        }) ?? []
                    );
                }
            );

            queryClient.setQueryData<TBooking>(SingleBookingQueryKey, (prev) => {
                return { ...prev!, ...payload };
            });

            return { previous, eventId: previous?.eventId, serviceId: previous?.serviceId };
        },
        onError: (err, variables, context: any) => {
            if (context?.previous) {
                queryClient.setQueryData<TBooking>(SingleBookingQueryKey, context.previous);
            }
        },
        onSettled: async (data, err, variables, context: any) => {
            await queryClient.invalidateQueries(BookingsQueryKey);
            await queryClient.invalidateQueries("events");
            queryClient.invalidateQueries(["bookings", "event", context?.eventId]);
            queryClient.invalidateQueries([
                "events",
                { type: "service", serviceId: context?.serviceId },
            ]);
        },
    });

    const updateBooking = useMutation(
        ({
            id,
            ...payload
        }: {
            id: string;
            internalNote?: string;
            paymentId?: string;
            eventId?: string;
            sessionId?: string;
            status?: TBooking["status"];
            transaction?: TBooking["transaction"];
        }) => {
            const existing = queryClient.getQueryData(["booking", id]) as undefined | object;

            return api.updateBooking(id, { ...(existing ?? {}), ...payload });
        },
        {
            onMutate: async ({ id, eventId, sessionId, ...data }) => {
                const singleQueryKey = ["booking", id];

                const payload = { created: new Date().toISOString(), ...data };

                await queryClient.cancelQueries(BookingsQueryKey);

                const previous = queryClient.getQueryData<TBooking>(singleQueryKey);

                queryClient.setQueryData<TBooking[]>(BookingsQueryKey, (prev) => {
                    return prev!.map((el) => {
                        return el.id === id ? { ...el, ...payload } : el;
                    });
                });

                queryClient.setQueryData<TBooking[]>(["bookings", "event", eventId], (prev) => {
                    return (
                        prev?.map((el) => {
                            return el.id === id ? { ...el, ...payload } : el;
                        }) ?? []
                    );
                });

                queryClient.setQueryData<TBooking[]>(["bookings", "session", sessionId], (prev) => {
                    return (
                        prev?.map((el) => {
                            return el.id === id ? { ...el, ...payload } : el;
                        }) ?? []
                    );
                });

                queryClient.setQueryData<TBooking>(singleQueryKey, (prev) => {
                    return { ...prev!, ...payload };
                });

                return { previous, eventId, serviceId: previous?.serviceId };
            },
            onError: (err, variables, context: any) => {
                if (context?.previous) {
                    queryClient.setQueryData<TBooking>(["booking", variables.id], context.previous);
                }
            },
            onSettled: async (data, err, variables, context: any) => {
                await Promise.all([
                    queryClient.invalidateQueries(BookingsQueryKey),
                    queryClient.invalidateQueries("events"),
                    queryClient.invalidateQueries(["booking", variables?.id]),
                ]);
                await Promise.all([
                    queryClient.invalidateQueries(["bookings", "event", context?.eventId]),
                    queryClient.invalidateQueries(["bookings", "session", context?.sessionId]),
                    queryClient.invalidateQueries([
                        "events",
                        { type: "service", serviceId: context?.serviceId },
                    ]),
                ]);
            },
        }
    );

    const createBooking = useMutation(
        async ({ id, ...payload }: TCreateBookingPayload & { id: string }) => {
            const { eventId } = await api.createBooking<TCreateBookingPayload, { eventId: string }>(
                id,
                payload
            );
            return eventId;
        },
        {
            mutationKey: "createBookingMutation",
            onMutate: async ({ id, eventId, ...data }) => {
                const payload = { ...data, created: new Date().toISOString() };

                queryClient.setQueryData(["booking", id], { ...payload, eventId });

                const forEventsKey = ["bookings", "event", eventId];

                const prevBookingsForEvents = queryClient.getQueryData(forEventsKey);
                const prevBookings = queryClient.getQueryData(BookingsQueryKey);

                queryClient.setQueryData<TCreateBookingPayload[]>(forEventsKey, (prev) => {
                    return [{ ...payload, id, eventId }, ...(prev ?? [])];
                });

                queryClient.setQueryData<TCreateBookingPayload[]>(BookingsQueryKey, (prev) => {
                    return [{ ...payload, id, eventId }, ...(prev ?? [])];
                });

                return { prevBookingsForEvents, prevBookings };
            },
            onError: async (err, variables, context: any) => {
                if (context?.prevBookingsForEvents) {
                    queryClient.setQueryData(
                        ["bookings", "event", variables.eventId],
                        context.prevBookingsForEvents
                    );
                }
                if (context?.prevBookings) {
                    queryClient.setQueryData(BookingsQueryKey, context.prevBookings);
                }
                throw err;
            },
            onSettled: async (newEventId, err, variables, context: any) => {
                await Promise.all([
                    queryClient.invalidateQueries(BookingsQueryKey),
                    queryClient.invalidateQueries("events"),
                    queryClient.invalidateQueries(["booking", variables?.id]),
                ]);
            },
        }
    );

    const createSessionBooking = useMutation(
        async ({ id, ...payload }: { id: string; startDateTime: string; endDateTime: string }) => {
            return api.createManualBookingForSession<
                any,
                { sessionId?: string; startDateTime: string; endDateTime: string }
            >(id, payload);
        },
        {
            mutationKey: "createSessionBookingMutation",
            onMutate: async ({ id, ...data }) => {
                const payload = { ...data, created: new Date().toISOString() };

                queryClient.setQueryData(["booking", id], { ...payload });

                const prevBookings = queryClient.getQueryData(BookingsQueryKey);

                queryClient.setQueryData<TBooking[]>(BookingsQueryKey, (prev) => {
                    return prev?.some((el) => el.id === id)
                        ? prev?.map((el) => {
                              return el.id === id ? { ...el, ...payload } : el;
                          })
                        : [{ ...payload, id } as TBooking, ...(prev ?? [])];
                });

                return { prevBookings };
            },
            onError: async (err, variables, context: any) => {
                if (context?.prevBookings) {
                    queryClient.setQueryData(BookingsQueryKey, context.prevBookings);
                }
                throw err;
            },
            onSettled: async (newEventId, err, variables, context: any) => {
                await queryClient.invalidateQueries(BookingsQueryKey);
                await Promise.all([queryClient.invalidateQueries(["booking", variables?.id])]);
                new Promise((res) => setTimeout(res, 5000)).then(() =>
                    queryClient.invalidateQueries(["offers"])
                );
            },
        }
    );

    const moveBooking = useMutation(
        async ({
            id,
            eventId,
            startDateTime,
            endDateTime,
            shouldNotify,
            message,
        }: {
            id: string;
            shouldNotify: boolean;
            eventId?: string;
            startDateTime?: string;
            endDateTime?: string;
            sessionId?: string;
            message?: string;
        }) => {
            const { newBookingId, newEventId } = await api.moveBooking(
                id,
                shouldNotify,
                eventId,
                startDateTime,
                endDateTime,
                message
            );
            return {
                newBookingId,
                newEventId,
            };
        },
        {
            mutationKey: "moveBookingMutation",
            onMutate: async ({ id, eventId, sessionId, startDateTime, endDateTime }) => {
                const prevBooking = queryClient.getQueryData<TBooking>(["booking", id]);

                if (eventId) {
                    const oldForEventsKey = ["bookings", "event", prevBooking?.eventId];
                    const newForEventsKey = ["bookings", "event", eventId];

                    const prevOldBookingsForEvents = queryClient.getQueryData(oldForEventsKey);
                    const prevNewBookingsForEvents = queryClient.getQueryData(newForEventsKey);

                    const prevBookings = queryClient.getQueryData(BookingsQueryKey);

                    queryClient.setQueryData<TBooking[]>(oldForEventsKey, (prev) => {
                        return (prev ?? []).map((el) => {
                            return el.id === bookingId
                                ? { ...el, status: "moved", movedToEvent: eventId }
                                : el;
                        });
                    });

                    queryClient.setQueryData<TBooking[]>(newForEventsKey, (prev) => {
                        return [
                            { ...((prevBooking ?? {}) as TBooking), id, eventId },
                            ...(prev ?? []),
                        ];
                    });

                    queryClient.setQueryData<TBooking[]>(BookingsQueryKey, (prev) => {
                        return (prev ?? []).map((el) => {
                            return el.id === bookingId
                                ? { ...el, status: "moved", movedToEvent: eventId }
                                : el;
                        });
                    });

                    queryClient.setQueryData<TBooking>(["booking", id], (prev) => {
                        return {
                            ...((prev ?? {}) as TBooking),
                            movedToEvent: eventId,
                            status: "moved",
                        };
                    });

                    return {
                        prevOldBookingsForEvents,
                        prevBooking,
                        prevBookings,
                        prevNewBookingsForEvents,
                    };
                } else if (sessionId && startDateTime && endDateTime) {
                    const bookingsForSession = ["bookings", "session", sessionId];

                    const prevBookingsForSession = queryClient.getQueryData(bookingsForSession);

                    const prevBookings = queryClient.getQueryData(BookingsQueryKey);

                    queryClient.setQueryData<TBooking[]>(bookingsForSession, (prev) => {
                        return (prev ?? []).map((el) => {
                            return el.id === id ? { ...el, startDateTime, endDateTime } : el;
                        });
                    });

                    queryClient.setQueryData<TBooking[]>(BookingsQueryKey, (prev) => {
                        return (prev ?? []).map((el) => {
                            return el.id === id ? { ...el, startDateTime, endDateTime } : el;
                        });
                    });

                    queryClient.setQueryData<TBooking>(["booking", id], (prev) => {
                        return {
                            ...((prev ?? {}) as TBooking),
                            startDateTime,
                            endDateTime,
                        };
                    });

                    return {
                        prevBooking,
                        prevBookings,
                        prevBookingsForSession,
                    };
                }
            },
            onError: async (err, variables, context: any) => {
                if (context?.prevOldBookingsForEvents) {
                    queryClient.setQueryData(
                        ["bookings", "event", context?.prevBooking?.eventId],
                        context.prevOldBookingsForEvents
                    );
                }
                if (context?.prevNewBookingsForEvents) {
                    queryClient.setQueryData(
                        ["bookings", "event", variables.eventId],
                        context.prevNewBookingsForEvents
                    );
                }
                if (context?.prevBooking) {
                    queryClient.setQueryData(["booking", variables.id], context.prevBooking);
                }
                if (context?.prevBookings) {
                    queryClient.setQueryData(BookingsQueryKey, context.prevBookings);
                }
                if (context?.prevBookingsForSession) {
                    queryClient.setQueryData(
                        ["bookings", "session", variables?.sessionId],
                        context.prevBookingsForSession
                    );
                }
                throw err;
            },
            onSettled: async (data, err, variables, context: any) => {
                await Promise.all([
                    queryClient.invalidateQueries(BookingsQueryKey),
                    queryClient.invalidateQueries("events"),
                    queryClient.invalidateQueries(["booking", variables?.id]),
                    queryClient.invalidateQueries(["booking", data?.newBookingId]),
                ]);
                await Promise.all([
                    queryClient.invalidateQueries(["bookings", "event", data?.newEventId]),
                    queryClient.invalidateQueries([
                        "bookings",
                        "event",
                        context?.prevBooking?.eventId,
                    ]),
                    queryClient.invalidateQueries([
                        "events",
                        { type: "service", serviceId: context.prevBooking?.serviceId },
                    ]),
                    queryClient.invalidateQueries(["bookings", "session", variables?.sessionId]),
                ]);
            },
        }
    );

    const deleteSessionBooking = useMutation(
        async (id: string) => {
            return api.deleteBooking(id);
        },
        {
            mutationKey: "deleteSessionBooking",
            onMutate: async (id) => {
                const prevBookings = queryClient.getQueryData(BookingsQueryKey);
                const prevBooking = queryClient.getQueryData(["booking", id]);
                queryClient.setQueryData<TBooking[]>(BookingsQueryKey, (prev) => {
                    return prev?.filter((el) => el.id !== id) ?? [];
                });

                return { prevBookings, prevBooking };
            },
            onError: async (err, variables, context: any) => {
                if (context?.prevBookings) {
                    queryClient.setQueryData(BookingsQueryKey, context.prevBookings);
                }
                throw err;
            },
            onSettled: async (id, err, variables, context: any) => {
                await queryClient.invalidateQueries(BookingsQueryKey);
                if (context.prevBooking?.sessionId) {
                    await queryClient.invalidateQueries([
                        "bookings",
                        "session",
                        context.prevBooking.sessionId,
                    ]);
                }
            },
        }
    );

    const getBookingsForServiceCount = useCallback(
        (serviceId?: string) => {
            return bookings.data?.filter((el) => {
                return (
                    new Date(el.startDateTime) > new Date() &&
                    el.serviceId === serviceId &&
                    (el.status === "paid" || el.status === "unpaid")
                );
            })?.length;
        },
        [bookings.data]
    );
    const getServiceIdFromBooking = useCallback(
        (bookingId?: string) => {
            return bookings.data?.find((el) => el.id === bookingId)?.serviceId;
        },
        [bookings.data]
    );
    const getServiceName = useCallback(
        (serviceId?: string) => {
            return localize(services.data?.find((el) => el.id === serviceId)?.headline);
        },
        [services.data]
    );

    const getBooking = useCallback(
        (bookingId: string) => {
            return bookings.data?.find((el) => el.id === bookingId);
        },
        [bookings.data]
    );

    const bookingCountWithoutBlocker = useMemo(() => {
        return bookings.data?.filter((el) => el.type !== "blocker")?.length;
    }, [bookings.data]);

    const willOverlap = useCallback(
        (
            start: string | Date,
            end: string | Date,
            ownId: string,
            padding = 0,
            assignees?: string[]
        ): { type: "violatePadding" | "overlapping"; bookingId: string } | undefined => {
            const handler = (startDate: Date, endDate: Date) =>
                bookings.data?.find((b) => {
                    if (
                        b.id !== ownId &&
                        b.type !== "blocker" &&
                        userinfo.data?.sub &&
                        (b.assignees?.includes(userinfo.data.sub) ||
                            b.assignees?.some((a) => assignees?.includes(a) ?? false))
                    ) {
                        try {
                            return areIntervalsOverlapping(
                                { start: startDate, end: endDate },
                                { start: new Date(b.startDateTime), end: new Date(b.endDateTime) }
                            );
                        } catch (err) {
                            return false;
                        }
                    }
                    return false;
                });

            const overlapping = handler(new Date(start), new Date(end));
            if (overlapping) {
                return {
                    type: "overlapping",
                    bookingId: overlapping.id,
                };
            }
            const violatePadding = handler(
                addMinutes(new Date(start), -padding),
                addMinutes(new Date(end), padding)
            );
            if (violatePadding) {
                return {
                    type: "violatePadding",
                    bookingId: violatePadding.id,
                };
            }
            return undefined;
        },
        [bookings, userinfo]
    );

    return {
        booking,
        getServiceName,
        getServiceIdFromBooking,
        enrichedBooking,
        bookings,
        bookingsForEvent,
        bookingsForSession,
        introSessionsForSession,
        cancelBooking,
        moveBooking,
        refundBooking,
        createBooking,
        createSessionBooking,
        deleteSessionBooking,
        updateBooking,
        getBookingsForServiceCount,
        bookingsForCustomer,
        getBooking,
        bookingCountWithoutBlocker,
        willOverlap,
    };
};
